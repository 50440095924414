export function useTopOfPage() {
  const [topOfPage, setTopOfPage] = React.useState(true)

  React.useEffect(
    () => {
      window.addEventListener('scroll', handleScroll)
      return () => window.addEventListener('scroll', handleScroll)

      function handleScroll() {
        setTopOfPage(window.scrollY <= 0)
      }
    },
    []
  )

  return topOfPage
}
