import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { Tile } from '/features/buildingBlocks/tile/Tile'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useTopOfPage } from '/machinery/useTopOfPage'
import { resolveTileLink } from '/machinery/link'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { ShowRewardCalculatorPortalFlowButton } from '/features/buildingBlocks/reward-calculator/ShowRewardCalculatorPortalFlowButton'

import mediaStyles from '/cssGlobal/media.css'
import styles from './TileGrid.css'

export function TileGrid({ tiles, layoutClassName, linkRef = undefined, availableStyleContexts = undefined }) {
  const isMounted = useRenderOnMount()
  const topOfPage = useTopOfPage()
  const viewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const language = useLanguage()
  const compactGrid = viewportMd ? topOfPage : false
  const { __ } = useTranslate()

  return (
    <ul className={cx(styles.component, layoutClassName)}>
      {isMounted && (
        tiles.filter(x => x.representation).map((x, i) => (
          <li
            data-context-tile-index={i}
            key={[i, x._id].join()}
            className={cx(styles.gridItem, compactGrid && styles.compact)}
          >
            <Tile
              blankTile={topOfPage && viewportMd}
              representation={x.representation}
              href={resolveTileLink({ tile: x, language })}
              {...{ availableStyleContexts }}
            />
          </li>

        ))
      )}
      {isMounted && (
        <li
          data-context-tile-index={3}
          className={cx(styles.gridItem, compactGrid && styles.compact)}
        >
          <ShowRewardCalculatorPortalFlowButton
            {...{ linkRef }}
            layoutClassName={styles.calculatorLayout}
            label={__`salary-checker`}
            buttonType='tile'
            dataX='link-to-salary-detail'
          />
        </li>
      )}
    </ul>
  )
}
